<template>
  <div id="app" class="whatsapp-widget">
    <div id="backlayer"></div>

    <img id="whats-button"
      src="https://s3.sa-east-1.amazonaws.com/ferramentas.cloudpark.com.br/cloudpark-whatsapp-widget/whats.png" alt=""
      @click="toggleWidgetExpand">

    <section v-if="showOptions">
      <header>
        <a class="close-widget" @click="toggleWidgetExpand" type="submit">X</a>
        <span>
          O que deseja fazer?
        </span>
      </header>
      <div class="body">
        <button @click="onSuportButton">Sou cliente e quero contato</button>
        <button @click="onComercialButton">Quero uma proposta comercial</button>
      </div>
    </section>

    <section v-else-if="showWhatsForm">
      <header>
        <a class="close-widget" @click="toggleWidgetExpand">X</a>
        <h2>Olá 👋</h2>
        <span>
          Seja bem-vindo! Preencha os campos abaixo para iniciar a conversa no WhatsApp
        </span>
      </header>
      <form class="body" @submit.prevent="submitForm">
        <div class="input-container">
          <input type="text" name='name' placeholder="Nome *" v-model="nameInputValue">
          <input type="text" name='email' placeholder="Email *" v-model="emailInputValue">
          <input type="text" name='phone' placeholder="Número Whatsapp *" v-model="phoneInputValue"
            @input="formatPhoneInputValue">
          <select name='companyTypeInputValue' id='companyTypeInputValue' v-model='companyTypeInputValue'>
            <option value="">Tipo de empresa</option>
            <option value="Estacionamento">Estacionamento</option>
            <option value="Shopping/Centro Comercial">Shopping/Centro Comercial</option>
            <option value="Mercado">Mercado</option>
            <option value="Hotel">Hotel</option>
            <option value="Instituição de Ensino">Instituição de Ensino</option>
            <option value="Outros">Outros</option>
          </select>
          <select name='parkAmountInputValue' id='parkAmountInputValue' v-model='parkAmountInputValue'>
            <option value="">Quantidade de pátios</option>
            <option value="Nenhum">Nenhum</option>
            <option value="Estou abrindo">Estou abrindo</option>
            <option value="1 a 10 unidades">1 a 10 unidades</option>
            <option value="11 a 30 unidades">11 a 30 unidades</option>
            <option value="Acima de 30 unidades">Acima de 30 unidades</option>
          </select>
          <span v-if="errorMessage != ''" class="error" v-html="errorMessage"></span>
        </div>
        <button @click="onStartChatWithCommercialButton">Iniciar a conversa</button>
      </form>
    </section>

    <section v-else-if="showThankyou">
      <header>
        <a class="close-widget" @click="toggleWidgetExpand" type="submit">X</a>
        <h2>Obrigado pelo contato 👋</h2>
        <span>
          Você será redirecionado para o WhatsApp <br> {{ this.redirectPhone }}
        </span>
      </header>
      <div class="body">
        <button @click="toggleWidgetExpand">Voltar para o material</button>
      </div>
    </section>

  </div>
</template>
<script>

export default {
  data() {
    return {
      showWhatsForm: false,
      showOptions: false,
      showBackLayer: false,
      showThankyou: false,
      errorMessage: '',
      nameInputValue: '',
      emailInputValue: '',
      phoneInputValue: '',
      companyTypeInputValue: '',
      parkAmountInputValue: '',
      suportPhoneNumber: '41991079962',
      commercialPhoneNumber: '4730375851',
      redirectPhone: '',
    }
  },
  methods: {
    formatPhoneInputValue() {
      this.phoneInputValue = this.formatPhone(this.phoneInputValue)
    },
    formatPhone(phoneNumber) {
      phoneNumber = phoneNumber.replace('+55', '').trim()
      phoneNumber = phoneNumber.replace(/\D/g, '');

      if (phoneNumber.length === 0) {
        return '';
      }
      var formattedPhoneNumber = phoneNumber
      if (phoneNumber.length > 2) {
        formattedPhoneNumber = `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2)}`
      }
      if (phoneNumber.length > 5) {
        formattedPhoneNumber = `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2, 5)}-${phoneNumber.substr(7)}`
      }
      return formattedPhoneNumber
    },
    submitForm() {
      // Handle form submission here
      console.log('Form submitted ');
    },
    sendWhatsAppMessage(number, text = '') {
      const uri = `whatsapp://send/?phone=${encodeURIComponent(number)}&text=${encodeURIComponent(text)}`;


      const openedWhatsapp = new Promise((resolve) => {
        const a = document.getElementById("wapp-launcher") || document.createElement("a");
        a.id = "wapp-launcher";
        a.href = uri;
        // a.href = conversaUrl;
        a.style.display = "none";
        document.body.appendChild(a);

        const start = Date.now();
        const timeoutToken = setTimeout(() => {
          if (Date.now() - start > 1250) {
            resolve(true);
          } else {
            resolve(false);
          }
          // document.removeEventListener('blur', handleBlur);
        }, 1000);

        const handleBlur = () => {
          clearTimeout(timeoutToken);
          resolve(true);
        };
        window.addEventListener("blur", handleBlur);

        a.click();
      });

      openedWhatsapp.then(hasWhatsapp => {
        if (!hasWhatsapp) {
          const uri = `https://web.whatsapp.com/send?phone=${encodeURIComponent(number)}&text=${encodeURIComponent(text)}`;
          window.open(uri)
        }
      })

    },
    closeWidget() {
      this.showWhatsForm = false
    },
    onComercialButton() {
      this.showWhatsForm = true
      this.showOptions = false
    },
    onSuportButton() {
      const message = 'Oi, estava vendo os materiais e gostaria de suporte'
      this.redirectPhone = ''
      this.sendWhatsAppMessage(this.suportPhoneNumber, message)
      this.showThankyou = true
      this.showOptions = false

    },
    toggleWidgetExpand() {
      this.toggleBackLayer()

      if (this.showWhatsForm || this.showOptions || this.showThankyou) {
        this.showWhatsForm = false
        this.showOptions = false
        this.showThankyou = false
      } else {
        this.showOptions = true
      }
    },
    toggleBackLayer() {
      const appDiv = document.getElementById('backlayer');
      this.showBackLayer = !this.showBackLayer
      if (this.showBackLayer) {
        appDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        appDiv.style.pointerEvents = 'all';
      } else {
        appDiv.style.backgroundColor = 'rgba(0, 0, 0, 0)';
        appDiv.style.pointerEvents = 'none';
      }
    },
    async onStartChatWithCommercialButton() {
      this.errorMessage = ''
      if (this.nameInputValue == '') {
        this.errorMessage += '- Informe o nome <br>'
      }
      if (this.emailInputValue == '') {
        this.errorMessage += '- Informe o email <br>'
      }
      if (this.phoneInputValue.length < 7) {
        this.errorMessage += '- Informe o telefone <br>'
      }
      if (this.companyTypeInputValue == '') {
        this.errorMessage += '- Informe o tipo de empresa <br>'
      }
      if (this.parkAmountInputValue == '') {
        this.errorMessage += '- Informe a quantidade de pátios <br>'
      }
      if (this.errorMessage != '') {
        return
      }
      const message = 'oi, estava vendo os materiais e gostaria de um orçamento'
      this.redirectPhone = this.formatPhone(this.commercialPhoneNumber)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmitted',
        'formName': 'whatsapp_form_submitted'
      });
      this.sendWhatsAppMessage(this.commercialPhoneNumber, message)
      await this.sendConvertionToRd()
      await this.sendDataToWebhook();
      this.showThankyou = true
      this.showWhatsForm = false
    },
    async sendConvertionToRd() {

      var currentURL = window.location.pathname
      currentURL = currentURL.startsWith('/') ? currentURL.substring(1) : currentURL
      if (currentURL == '') {
        currentURL = window.location.hostname;
      }

      currentURL = 'whatsapp-' + currentURL
      console.log('currentURL', currentURL)

      const phone = '+55' + this.phoneInputValue;

      var dataArray = [
        { name: 'email', value: this.emailInputValue },
        { name: 'identificador', value: currentURL },
        { name: 'token_rdstation', value: "cce9d0e5a7931895fb2520e37184a7bc" },
        { name: 'nome', value: this.nameInputValue },
        { name: 'Telefone', value: phone },
        { name: 'cf_qual_seu_segmento', value: this.companyTypeInputValue },
        { name: 'cf_quantos_unidades_voce_gerencia', value: this.parkAmountInputValue },
      ];
      const response = await window.RdIntegration.post(dataArray);
      console.log('response', response)
    },
    async sendDataToWebhook() {
      var hook_url = 'https://hook.us1.make.com/qbgbkh1s0t5vxkadpay4ybn7959waorf'
      const phone = '+55' + this.phoneInputValue.replace(/\D/g, '');

      const payload = {
        nome: this.nameInputValue,
        email: this.emailInputValue,
        whatsapp: phone,
        tipo_de_empresa: this.companyTypeInputValue,
        quantidade_de_patios: this.parkAmountInputValue
      };

      try {
        const response = await fetch(hook_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Falha ao enviar dados para o webhook');
        }

      } catch (error) {
        console.error('Erro ao enviar dados para o webhook:', error);
      }
    }

  }
};
</script>



<style scoped>
.whatsapp-widget #backlayer {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.whatsapp-widget {
  position: relative;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.whatsapp-widget section {
  width: 450px;
  position: fixed;
  bottom: 90px;
  right: 15px;
}

@media (max-width: 600px) {
  .whatsapp-widget section {
    width: 95%;
    margin: 10px;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;

  }
}

.whatsapp-widget img {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 60px;
  height: 60px;

  filter: drop-shadow(2px 2px 6px #0000002a);
  cursor: pointer;
}

.whatsapp-widget .cta-message {
  pointer-events: none;
  color: rgba(38, 92, 84, 1);
  font-weight: bold;
}

.whatsapp-widget .widget {
  color: white;
  border-radius: 360px;
  cursor: pointer;
}

.whatsapp-widget section {
  display: flex;
  flex-direction: column;
  background-color: rgba(235, 229, 222, 1);
}

.whatsapp-widget header {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: rgba(38, 92, 84, 1);
  color: white;

}

.whatsapp-widget .close-widget {
  position: absolute;
  width: 10px;
  right: 20px;
  cursor: pointer;
}

.whatsapp-widget h2 {
  margin: 0px;
  font-size: 30px;
}

.whatsapp-widget section header span {
  line-height: 140%;
}

.whatsapp-widget section .body {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
}

.whatsapp-widget .input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
}

.whatsapp-widget .input-container span {
  color: white;
}

.whatsapp-widget input {
  border-radius: 5px;
  border: none;
  padding: 10px 8px;
  border: solid 1px rgb(197, 197, 197);
  background-color: rgb(250, 250, 250);

  font-size: 14px;
}


.whatsapp-widget select {
  border-radius: 5px;
  border: none;
  padding: 10px 8px;
  border: solid 1px rgb(197, 197, 197);
  background-color: white;

  font-size: 14px;
}

.whatsapp-widget button {
  border-radius: 5px;
  border: none;
  padding: 15px;
  background-color: rgba(83, 164, 81, 1);
  color: white;
  font-size: medium;
  font-size: 18px;
}

.whatsapp-widget span.error {
  color: red;
  padding: 0px 10px;
  font-size: 16px;
  /* font-weight: bold; */
}

.whatsapp-widget button {
  cursor: pointer;
}
</style>
